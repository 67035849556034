import React from 'react'
import { graphql } from 'gatsby'
import TabbedPage from '../../components/Layouts/TabbedPage'
import { extract } from '../../utils/graphql'
import { localizePath } from '../../../config/i18n'

import NoTranslation from '../../components/Templates/NoTranslation'
import formatDate from '../../utils/formatDate'
import ThreeUp from '../../components/Layouts/ThreeUp'
import ContentCard from '../../components/General/Cards/Content'
import { withPreview } from 'gatsby-source-prismic'

const Page = ({
  data: {
    prismicResearchInnovation,
    researchInnovationEnglish,
    prismicTeamResearchPapers,
    prismicStandardsProposals,
    teamResearchEnglish,
  },
  pageContext,
}) => {
  const page = prismicResearchInnovation

  if (!page) return <NoTranslation></NoTranslation>

  const hydratedData = {
    lang: pageContext.locale,
    seo: extract.seo(page),
    mantelData: extract.mantel(page, {
      parent: {
        path: localizePath(pageContext.locale, '/resources'),
        text: 'Resources',
      },
    }),
    tabs: [
      {
        active: true,
        url: localizePath(pageContext.locale, '/publications/'),
        text:
          page.data.tab_text.text ||
          researchInnovationEnglish.data.tab_text.text,
      },
      {
        active: false,
        url: localizePath(pageContext.locale, '/peer-reviewed-papers/'),
        text: prismicTeamResearchPapers
          ? prismicTeamResearchPapers.data.tab_text.text
          : teamResearchEnglish.data.tab_text.text,
      },
      {
        active: false,
        url: localizePath(pageContext.locale, '/standards-proposals/'),
        text: prismicStandardsProposals
          ? prismicStandardsProposals.data.tab_text.text
          : 'Standards Proposals',
      },
    ],
    list: page.data.resources
      .filter(({ resource }) => resource.data !== null)
      /* eslint-disable-next-line */
      .map(({ resource, resource_image }) => {
        const date =
          resource && resource.data && formatDate(resource.document.data.date)
        const title =
          resource && resource.data && resource.document.data.title.text
        return {
          image: {
            src: resource_image.url,
            alt: resource_image.alt,
          },
          date,
          title,
          button: {
            text: page.data.cta_button_text.text,
            url: resource.url,
          },
        }
      }),
  }

  return (
    <TabbedPage {...hydratedData}>
      <ThreeUp>
        {hydratedData.list.map((item) => (
          <ContentCard {...item} staySmall textGrey />
        ))}
      </ThreeUp>
    </TabbedPage>
  )
}

export default withPreview(Page)

export const pageQuery = graphql`
  query($locale: String!) {
    prismicResearchInnovation(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        seo_keywords {
          keyword {
            text
          }
        }
        seo_description {
          text
        }
        mantel_title {
          text
        }
        mantel_content {
          html
        }
        cta_button_text {
          text
        }
        tab_text {
          text
        }
        resources {
          resource {
            url
            document {
              ... on PrismicArticle {
                id
                data {
                  article_type
                  title {
                    text
                  }
                  date
                }
              }
            }
          }
          resource_image {
            url
            alt
          }
        }
      }
    }
    researchInnovationEnglish: prismicResearchInnovation(
      lang: { eq: "en-us" }
    ) {
      data {
        tab_text {
          text
        }
      }
    }
    prismicTeamResearchPapers(lang: { eq: $locale }) {
      data {
        tab_text {
          text
        }
      }
    }
    prismicStandardsProposals(lang: { eq: $locale }) {
      data {
        tab_text {
          text
        }
      }
    }
    teamResearchEnglish: prismicTeamResearchPapers(lang: { eq: "en-us" }) {
      data {
        tab_text {
          text
        }
      }
    }
  }
`
